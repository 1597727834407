<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelPacketsReceiveProcess" />
      </template>

      <template v-slot:title>
        <h1>{{ $t("packetsScanned.received") }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="container">
        <div class="text-center list" v-if="emptyItems">
          <h1>{{ $t("packetsScanned.no-packets") }}</h1>
        </div>
        <Errors :errors="errors" />
        <ScannedListItem
          v-for="(item, index) in items"
          :key="index"
          :success="item.success"
          :scannedValue="item.scanned_value"
          :recipient="item.recipient"
          :status="item.human_state || item.failure_reason"
        >
          <template v-slot:center>
            <p v-if="item.shelf_code">
              {{ item.shelf_code }}
            </p>
          </template>
        </ScannedListItem>
      </section>
    </main>
    <footer ref="footer">
      <div class="container">
        <a
          class="btn btn-primary btn-white"
          @click.prevent="printLabel"
          v-if="!emptyItems && batchId"
          >{{ $t("packetsScanned.print") }}</a
        >
        <a
          class="btn btn-primary btn-green"
          @click.prevent="cancelPacketsReceiveProcess"
          >{{ $t("packetC2Creceive.finish") }}</a
        >
      </div>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Close from "vue-material-design-icons/Close.vue";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import { MOBILE_TYPES } from "@/util/mobile.type";
import AppHeader from "@/components/AppHeader";
import Errors from "@/components/Errors";
import Spinner from "@/components/Spinner";
import ScannedListItem from "@/components/ScannedList/Item";

export default {
  name: "PacketsReceiveProcess",
  mixins: [setActualFooterHeight, getParams],
  components: { AppHeader, Errors, Spinner, ScannedListItem, Close },
  data() {
    return {
      batchId: this.$route.params.batchId
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  async mounted() {
    await this.getScannedPackets(this.batchId);
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("scanned", ["items"]),
    ...mapGetters("scanned", ["emptyItems"])
  },

  methods: {
    ...mapActions("scanned", [
      "getScannedPackets",
      "reprintScannedPacketsLabels"
    ]),
    ...mapMutations(["clearErrors"]),

    cancelPacketsReceiveProcess() {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.backToScan();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.backToScan.postMessage({});
        }
      } else {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }
    },

    async printLabel() {
      await this.reprintScannedPacketsLabels(this.batchId);
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  .list {
    margin-top: 30px;
  }
}

footer {
  a:first-child {
    margin-bottom: 10px;
  }
}
</style>
