<template>
  <div class="item">
    <div class="d-flex item-left">
      <slot name="left">
        <check-icon v-if="success" class="icon green" />
        <warning-icon v-else-if="warning" class="icon orange" />
        <close-icon v-else class="icon red" />
        <div>
          <h3>{{ scannedValue }}</h3>
          <p class="smaller">
            {{ recipient || $t("packetsScanned.unknown") }}
          </p>
        </div>
      </slot>
    </div>

    <div class="d-flex item-center">
      <slot name="center" />
    </div>

    <div class="d-flex item-right" :class="{ 'flex-col': !!subStatus }">
      <slot name="right">
        <p class="status smaller" :class="statusClasses">
          {{ status }}
        </p>
        <p v-if="subStatus" class="smaller" :class="{ orange: warning }">
          {{ subStatus }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import CheckIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import WarningIcon from "vue-material-design-icons/AlertOutline.vue";

export default {
  name: "ScannedListItem",
  components: { CheckIcon, CloseIcon, WarningIcon },
  props: {
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    scannedValue: {
      type: String,
      required: true
    },
    recipient: {
      type: String
    },
    status: {
      type: String
    },
    statusClass: {
      type: String
    },
    subStatus: {
      type: String
    }
  },

  computed: {
    statusClasses() {
      return {
        red: !this.success && !this.warning,
        orange: this.warning,
        [this.statusClass]: !!this.statusClass
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-columns: 5fr 1fr 4fr;
  justify-content: start;
  align-items: center;
  padding: 6px 16px;
  border-bottom: 1px solid $grey;

  &-left {
    align-items: center;
  }

  &-center {
    justify-content: center;
  }

  &-right {
    justify-content: flex-end;
    text-align: right;
  }

  p {
    color: $light-grey;
  }

  .icon {
    display: inline-flex;
    margin-right: 9px;
  }

  .red {
    color: $orange-red;
  }

  .orange {
    color: $orange-bright;
  }
}

// Specific status classes
.status {
  @include parcel-statuses;
}
</style>
